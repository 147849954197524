import type { AxiosError } from 'axios'
import axios from 'axios'
import { Constants } from '../utils/enums'
import { createDiscreteApi } from 'naive-ui'
const { message } = createDiscreteApi(['message'])

// Define Axios Response / Error Types
export type _AxiosError = AxiosError & {
  response: {
    data: { message: string }
  }
}
const loginUrl = '/auth/anonymous/login'
const refreshUrl = '/auth/anonymous/refresh'

const axiosHotelLovers = axios.create({
  baseURL: Constants['BASE_URL_API'],
  withCredentials: true,
  timeout: 300_000 // 5分
})
axiosHotelLovers.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    if (err.config && err.response?.status == 401) {
      if (err.config.url !== refreshUrl) {
        return axiosHotelLovers.post(refreshUrl, err.config.params).then(() => {
          // loginチェックの失敗はrefresh完了してればretry不要
          if (err.config.url !== loginUrl) {
            return axiosHotelLovers.request(err.config)
          }
        })
      } else {
        // refresh失敗はchatbotViewの方でsignupさせるのでスルー（会話の途中でsignupを許容しないのでapi.tsではやらない）
      }
    } else if (err.code === 'ECONNABORTED') {
      message.error('タイムアウトしました')
    } else if (!err?.response) {
      message.error('ネットワークエラーが発生しました')
    }
    return Promise.reject(err)
  }
)
export default axiosHotelLovers
