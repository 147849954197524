<script setup lang="ts">
import { colorPalette as colors, Constants } from '../utils/enums'
import { computed, ref } from 'vue'
import ChatInput from '@/components/ChatInput.vue'
import { onMounted } from 'vue'
import { useConversationStore } from '@/stores/conversationStore'
// import LoadingWithAbort from '@/components/LoadingWithAbort.vue'
import { type ChatMessageDetailTransformed, type GetHistoryQuery } from '@/types/conversation.type'
import { useAuthStore } from '@/stores/authStore'
import { Promotion } from '@element-plus/icons-vue'
defineProps<{
  isInspectMode: boolean
}>()
const conversationStore = useConversationStore()
const authStore = useAuthStore()
const getHistoryQuery = ref<GetHistoryQuery>({
  page: 1,
  limit: 50
})
const isSp = navigator.userAgent.match(/iPhone|Android.+Mobile/)

async function init() {
  conversationStore.clear_current_conversation()
  authStore.clear_current_user()
  const gpId = await authStore.load_gp_cookie().catch(() => null)

  // auth check
  await authStore
    .login_anonymous(gpId)
    .then(() => {
      console.log('login success')
    })
    .catch(async () => {
      // login, refreshともに失敗したケース
      conversationStore.clear_conversation_id()
      await authStore.signout_anonymous()
      await authStore.signup_anonymous(gpId)
    })

  // conversationId
  const cId = conversationStore.get_conversation_id()
  if (cId !== -1) {
    setCurrentConversation(cId)
  }
  conversationStore.get_history(getHistoryQuery.value)
}
function setCurrentConversation(id: number) {
  conversationStore.clear_current_conversation()
  conversationStore.get_one_history(id)
}

const currentTransactions = computed(() => {
  return conversationStore.get_current_transactions
})
const currentConditionItems = computed(() => {
  return conversationStore.currentState
})
function sortMessages(messages: ChatMessageDetailTransformed[]) {
  return messages.sort((a, b) => {
    return a.id - b.id
  })
}

// history & pagination
// const conversationItems = computed(() => {
//   return conversationStore.conversations
// })
// const currentPage = computed({
//   get: () => getHistoryQuery.value.page,
//   set: (val) => (getHistoryQuery.value.page = val)
// })
// function changePage(v: number): void {
//   getHistoryQuery.value.page = v
//   conversationStore.get_history(getHistoryQuery.value)
// }
// const totalPages = computed(() => {
//   return conversationStore.conversations_stats?.totalPages || 1
// })

// init
init()
onMounted(() => {
  scrollToBottomMessage()
})

function messageFormatter(text: string) {
  return text.replaceAll('\n', '<br/>')
}

// chatInput, Loading, Abort
const chatInput = ref<InstanceType<typeof ChatInput> | null>(null)
const currentAc = ref<AbortController | null>(null)
const isLoading = computed(() => currentAc.value !== null)
const handleCancelRequest = () => {
  if (currentAc.value) {
    currentAc.value.abort()
  }
  currentAc.value = null
}

async function handleSend(text: string) {
  currentAc.value = new AbortController()
  // set or create conversationId
  const conversationId = await (() => {
    if (conversationStore.currentConversation) {
      return conversationStore.currentConversation.id
    } else {
      return conversationStore.start(text)
    }
  })()

  // routingConcierge
  const res = await conversationStore
    .routingConcierge(text, conversationId)
    .catch(() => handleCancelRequest())

  if (!res || res.moderationFlagged) {
    // moderationFlagged or UnexpectedError
    handleCancelRequest()
    clearInputAndScroll()
    return
  }

  currentAc.value = new AbortController()
  switch (res.route) {
    case 'ShoppingAssistant':
      conversationStore
        .chatShoppingAssistant(conversationId, res.tx.id)
        .then(() => {
          clearInputAndScroll()
        })
        .catch(() => {
          handleCancelRequest()
        })
      break
    case 'SmallTalkPartner':
    case 'CustomerSupportOperator':
    case 'Other':
      conversationStore
        .postMinorPipeline(conversationId, res.tx.id, res.route)
        .then(() => {
          clearInputAndScroll()
        })
        .catch(() => {
          handleCancelRequest()
        })
      break
    default:
      console.log('invalid')
      break
  }
}
function clearInputAndScroll() {
  chatInput.value?.clearInput()
  currentAc.value = null
  scrollToBottomMessage()
}
const disclaimerText = `・ご期待にあった返答ができない場合があります<br/>
・学習済みのデータ（商品データと口コミデータ）にない情報は返答できません<br/>
    ・活用するとよさそうな情報があればフィードバックをお願いします<br/>
・丁寧な言葉を使わなくても、実際のお客様と同じように話しかけていただいて大丈夫です<br/>
`
const currentConversationIdLabel = computed(() => {
  if (conversationStore.currentConversation) {
    return '(会話ID) ' + conversationStore.currentConversation.id
  } else {
    return '(会話ID) ... '
  }
})

function scrollToBottomMessage() {
  // 1番下までscrollする
  const wrapperDivs = document.querySelectorAll('.main-area')
  if (wrapperDivs.length) {
    wrapperDivs[0].scrollTop = wrapperDivs[0].scrollHeight
  }
}

// userRating
const USER_RATING_DEFAULT = -1
const userRatingLabels = {
  1: '最悪',
  2: '残念',
  3: '普通',
  4: '良い',
  5: '最高'
}
const alreadyRatedTxIds = computed(() => {
  return conversationStore.alreadyRatedTxIds
})
const updateUserRating = (txId: number, userRating: number) => {
  if (!conversationStore.currentConversation || userRating == USER_RATING_DEFAULT) {
    return
  }
  conversationStore.update_user_rating(conversationStore.currentConversation.id, txId, userRating)
}
// transit
async function newChat() {
  await conversationStore.clear_conversation_id()
  init()
}
function openItemPage(url: string) {
  // in a new tab
  let w = window.open()
  if (w) {
    w.opener = null
    w.location = url
  }
}
function openConsole() {
  const cId = conversationStore.currentConversation?.id
  if (!cId) {
    return
  }
  // in a new tab
  let w = window.open()
  if (w) {
    w.opener = null
    w.location = Constants.BASE_URL_CONSOLE + '/#/monitoring?conversationId=' + cId
  }
}
</script>

<template>
  <div class="search-wrapper">
    <div class="header-wrapper">
      <div class="sp-wrapper">
        <div class="header">
          <div class="header-left">
            <template v-if="isInspectMode">
              <div class="conversation-id">{{ currentConversationIdLabel }}</div>
              <el-button
                v-if="!isSp"
                style="margin-left: 12px"
                :disabled="!conversationStore.currentConversation"
                @click="openConsole()"
                >会話分析を開く</el-button
              >
            </template>
            <el-button style="margin-left: 12px" @click="newChat()">New Chat</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="sp-wrapper">
      <div class="main-area">
        <div class="search-conditions-wrapper">
          <div class="search-icon">
            <img
              src="../assets/images/ic-search.png"
              srcset="../assets/images/ic-search@2x.png 2x, ../assets/images/ic-search@3x.png 3x"
            />
          </div>
          <div class="search-condition-item" v-for="(row, i) in currentConditionItems" :key="i">
            <span v-if="row.key.length">{{ row.key }}: </span>{{ row.label }}
          </div>
          <div v-if="!currentConditionItems.length" class="placeholder">検索条件の指定なし</div>
        </div>
        <div class="disclaimer-flex-container">
          <div class="disclaimer-container">
            <div class="disclaimer-title">
              <img
                src="../assets/images/ic-info.png"
                srcset="../assets/images/ic-info@2x.png 2x, ../assets/images/ic-info@3x.png 3x"
              />
              社内試験運用版です。皆様のフィードバックによって改善されていきます。
            </div>
            <div class="disclaimer-text" v-html="disclaimerText" />
          </div>
        </div>
        <!-- 自動のファーストメッセージ -->
        <div class="message">
          <div class="assistant-message-container">
            <div class="assistant-icon">
              <img src="../assets/images/ic-chaticonbear.jpg" />
            </div>
            <div class="assistant-message-text">
              <div class="assistant-message-text-body">
                こんにちわ！<br />どんなカラコンをお探しでしょうか？？
              </div>
            </div>
          </div>
        </div>
        <div v-for="tx in currentTransactions" :key="tx.id">
          <div v-for="m in sortMessages(tx.messages)" :key="m.id" class="message">
            <div v-if="m.role == 'system'" class="system-message">
              {{ m.text }}
            </div>
            <div v-if="m.role == 'user'" class="user-message-container">
              <div class="user-message-text" v-html="messageFormatter(m.text)" />
            </div>
            <div v-if="m.role == 'assistant'" class="assistant-message-container">
              <div class="assistant-icon">
                <img src="../assets/images/ic-chaticonbear.jpg" />
              </div>
              <div class="assistant-message-text">
                <div class="assistant-message-text-body" v-html="messageFormatter(m.text)" />
                <div v-for="(content, i) in m.additionalContents" :key="i">
                  <div
                    v-if="content.messageType == 'text'"
                    class="additional-references"
                    v-html="content.text"
                  />
                  <template v-if="content.messageType == 'flex'">
                    <div v-if="content.title" class="product-grid-title">{{ content.title }}</div>
                    <div class="product-grid">
                      <div
                        v-for="(item, j) in content.items"
                        :key="j"
                        class="product-grid-item"
                        @click="openItemPage(item.link)"
                      >
                        <img :src="item.imageUrl" />
                      </div>
                    </div>
                  </template>
                </div>
                <div v-if="!alreadyRatedTxIds.includes(tx.id)" class="user-rating">
                  <div class="assistant-message-text-body prompt-user-rating">
                    お役に立てましたか？
                  </div>
                  <div class="user-rating-form">
                    <el-radio-group v-model="tx.userRating" size="small">
                      <el-radio-button
                        v-for="item in [1, 2, 3, 4, 5]"
                        :key="item"
                        :label="userRatingLabels[item as keyof typeof userRatingLabels]"
                        :value="item"
                      />
                    </el-radio-group>
                    <el-button
                      v-if="tx.userRating !== -1"
                      type="primary"
                      :icon="Promotion"
                      size="small"
                      circle
                      @click="updateUserRating(tx.id, tx.userRating)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadingAnimation :show="isLoading" />

        <!-- <LoadingWithAbort v-if="isLoading" @cancel="handleCancelRequest" /> -->
        <ChatInput :isLoading="isLoading" ref="chatInput" @send="handleSend($event)" />
      </div>
    </div>
  </div>
</template>
<style scoped>
.search-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: v-bind('colors.bg.base');
}
.sp-wrapper {
  width: 100%;
  max-width: 1024px;
}
.header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 48px;
  background-color: v-bind('colors.bg.f');
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid v-bind('colors.border.divider');
}
.header {
  height: 100%;
  line-height: 48px;
  padding: 0 4px;
  display: flex;
  justify-content: space-between;
}
.header-left {
  display: flex;
  align-items: center;
  .btn {
    cursor: pointer;
    font-weight: bold;
    display: inline-block;
    padding: 0 4px;
    .icon {
      vertical-align: middle;
      margin-top: -2px;
      margin-right: 2px;
    }
  }
  .conversation-id {
    font-size: 12px;
    font-weight: bold;
    width: auto;
    color: v-bind('colors.text.lighter');
    margin-left: 8px;
  }
}
.main-area {
  padding: 72px 0 240px;
  height: calc(100% - 48px - 12px); /* chatInput, padding+buffer */
  overflow-y: scroll;
}
.disclaimer-flex-container {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
  height: auto;
  .disclaimer-container {
    /* width: media */
    background-color: v-bind('colors.bg.lighterPale');
    padding: 12px 16px;
    .disclaimer-title {
      min-height: 24px;
      line-height: 24px;
      font-size: 18px;
      font-weight: bold;
      color: v-bind('colors.text.base');
      margin-bottom: 4px;
      img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-right: 4px;
      }
    }
    .disclaimer-text {
      font-size: 14px;
      font-weight: bold;
      color: v-bind('colors.text.lighter');
      line-height: 18px;
      height: auto;
      padding-left: 4px;
    }
  }
}
/* message */
.system-message {
  margin-left: 60px;
  margin-right: 12px;
  font-size: 12px;
  font-weight: bold;
  color: v-bind('colors.text.lighter');
  vertical-align: middle;
  margin-top: 12px;
  height: auto;
  white-space: normal;
}
.user-message-container {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 12px;
  margin-right: 8px;
  margin-left: 112px;
  height: auto;
}
.user-message-text {
  width: auto;
  background-color: v-bind('colors.bg.accentPale');
  border-radius: 8px;
  padding: 16px 20px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: v-bind('colors.text.base');
  vertical-align: middle;
  white-space: normal;
}
.assistant-message-container {
  display: flex;
  margin-left: 8px;
  margin-right: 56px;
  vertical-align: middle;
  margin-top: 12px;
  height: auto;
  .assistant-icon {
    width: 44px;
    height: 44px;
    margin-top: 4px;
    margin-right: 8px;
    img {
      width: 44px;
      height: 44px;
      vertical-align: middle;
    }
  }
  .assistant-message-text {
    display: flex;
    flex-direction: column;
    background-color: v-bind('colors.bg.lighterPale');
    border-radius: 8px;
    padding: 16px 20px 16px 16px;
    gap: 12px;
    .assistant-message-text-title {
      font-size: 20px;
      font-weight: bold;
      color: v-bind('colors.text.black');
      margin-bottom: 12px;
    }
    .assistant-message-text-body {
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
      color: v-bind('colors.text.base');
      white-space: normal;
      &.prompt-user-rating {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 4px;
      }
    }
    .additional-references {
      * {
        font-size: 12px;
        font-weight: bold;
      }
      color: v-bind('colors.text.lighter');
    }
    .user-rating {
      margin-top: 8px;
      .user-rating-form {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
}
/* product grid */
.product-grid-title {
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
}
.product-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  width: 95%;
  height: auto;
}
.product-grid-item {
  width: 8%;
  min-width: 100px;
  height: auto;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
/* search conditions */
.search-conditions-wrapper {
  position: fixed;
  top: 48px;
  width: 100%;
  padding-left: 8px;
  height: 40px;
  display: flex;
  gap: 4px;
  background-color: v-bind('colors.bg.base');
  z-index: 100;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
}
.search-condition-item {
  display: inline-block;
  width: auto;
  height: 32px;
  line-height: 32px;
  padding: 0 8px;
  font-size: 12px;
  font-weight: bold;
}
.search-conditions-wrapper > .placeholder {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  font-weight: bold;
  color: v-bind('colors.text.lighter');
}
.search-conditions-edit {
  display: flex;
  align-items: center;
  margin-left: 4px;
  gap: 4px;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  font-weight: bold;
  color: v-bind('colors.text.lighter');
  cursor: pointer;
}
.search-icon {
  width: 20px;
  height: 20px;
}

@media (max-width: 1024px) {
  .disclaimer-container {
    width: 95%;
  }
  .user-message-container {
    .user-message-text {
      padding: 16px 8px 16px 12px;
    }
  }
  .assistant-message-container {
    .assistant-message-text {
      padding: 16px 8px 16px 12px;
    }
  }
  .assistant-message-container {
    margin-right: 32px;
  }
}
@media (min-width: 1024px) {
  .disclaimer-container {
    width: 80%;
  }
}
</style>
