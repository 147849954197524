import { defineStore } from 'pinia'
import type { _AxiosError } from '@/stores/api'
import axiosHotelLovers from '@/stores/api'
import type { User, UserDetail } from '@/types/user.type'
import axios from 'axios'
import { Constants } from '@/utils/enums'

interface State {
  current_user: UserDetail | null
}

export const useAuthStore = defineStore('authStore', {
  state: (): State => ({
    current_user: null
  }),
  getters: {
    is_anonymous_loggedin(): boolean {
      return !!this.current_user
    }
  },
  actions: {
    clear_current_user() {
      this.current_user = null
    },
    set_current_user(user: UserDetail) {
      this.current_user = user
    },
    load_gp_cookie() {
      if (Constants['ENV_NAME'] == 'dev') {
        return Promise.resolve(Math.random().toString(36).substring(2, 15))
      }
      return new Promise<string>((resolve, reject) => {
        axios
          .create({
            withCredentials: true,
            timeout: 300_000 // 5分
          })
          .get(Constants['GP_LOAD_COOKIE_URL'])
          .then((res) => {
            resolve(res.data.data.id)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    signup_anonymous(gpId: string | null) {
      return new Promise<User>((resolve, reject) => {
        axiosHotelLovers
          .post('/auth/anonymous/signup', { gpId })
          .then((res) => {
            this.set_current_user(res.data)
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            this.clear_current_user()
            reject(err)
          })
      })
    },
    login_anonymous(gpId: string | null) {
      return new Promise<User>((resolve, reject) => {
        axiosHotelLovers
          .post('/auth/anonymous/login', { gpId })
          .then((res) => {
            this.set_current_user(res.data)
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            this.clear_current_user()
            reject(err)
          })
      })
    },
    signout_anonymous() {
      this.clear_current_user()
      return new Promise<void>((resolve, reject) => {
        axiosHotelLovers
          .post('/auth/anonymous/signout')
          .then(() => {
            resolve()
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
})
